<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
        color="bg-gradient-info shadow-info border-radius-xl mt-n6"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
          >insights</v-icon
        >
      </v-avatar>
      <div class="ms-4">
        <h6 class="text-h6 text-typo font-weight-bold">折線圖</h6>
        <p class="font-weight-light text-secondary text-sm">
          {{ contentType === "order" ? "訂單數量" : "交易點數" }}
        </p>
      </div>
      <div class="my-2 ms-auto">
        <v-badge bottom bordered color="#e91e63" dot offset-x="4" offset-y="9">
        </v-badge>
        <span class="text-dark text-sm ms-3">
          {{ contentType === "order" ? "成立訂單數" : "成立交易點數" }}
        </span>
      </div>
      <div class="my-2 ms-5">
        <v-badge bottom bordered color="#344767" dot offset-x="4" offset-y="9">
        </v-badge>
        <span class="text-dark text-sm ms-3">
          {{ contentType === "order" ? "取消訂單數" : "取消交易點數" }}
        </span>
      </div>
      <div v-if="false" class="my-2 ms-5 me-5">
        <v-badge bottom bordered color="#9CD1DE" dot offset-x="4" offset-y="9">
        </v-badge>
        <span class="text-dark text-sm ms-3">
          {{ contentType === "order" ? "累積有效訂單數" : "累積有效交易點數" }}
        </span>
      </div>
    </div>
    <div class="card-padding pt-0 px-4">
      <div class="chart" style="height: 40vh; max-height: 50vh">
        <canvas :id="chartId" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "line-chart",
  props: {
    chartId: {
      type: String,
      default: "line-chart",
      required: false,
    },
    datasets: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    labels: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    contentType: {
      type: String,
      default: "order", // order訂單/points點數
    },
  },
  watch: {
    datasets: {
      handler() {
        this.chart.update();
      },
      deep: true,
    },
  },
  data: function () {
    return {
      lineChartId: "line-chart",
      chart: null,
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        // layout: {
        //   padding: 10
        // },
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            min: 0,
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "#c1c4ce5c",
            },
            ticks: {
              display: true,
              padding: 10,
              // stepSize: 1,
              color: "#b2b9bf",
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
              color: "#c1c4ce5c",
            },
            ticks: {
              display: true,
              color: "#b2b9bf",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log("initialize chart: " + this.chartId);

      this.chart = new Chart(
        document.getElementById(this.chartId).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.labels,
            datasets: this.datasets,
          },
          options: this.defaultOptions,
        }
      );
    },
  },
  beforeDestroyed() {
    console.log("beforeDestroyed");
    this.chart.destroy();
  },
};
</script>
