// import axios from "axios";

const version = "v1";
const basePath = "/core/stores/";

// 特店申請
export function updateStore(merchantId, storeId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/stores`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  if (storeId) {
    config = {
      method: "put",
      url: `${process.env.VUE_APP_COREAPIHOST}${basePath}${version}/merchants/${merchantId}/stores/${storeId}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
  }
  return window.qcsso.request(config);
}
