<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <!-- <input
        ref="pac-input"
        id="pac-input"
        type="text"
        placeholder="搜尋店名"
        v-model="googleStore.googleStore"
      />
      <div ref="map" class="map" id="map"></div> -->
      <!-- <GoogleMap :data="googleStore" :editable="true"></GoogleMap> -->
      <v-row>
        <v-col cols="12" md="12" lg="8">
          <v-card class="px-1 py-1">
            <v-card-text>
              <p class="text-h5 text--primary">審核狀態</p>
              <v-row>
                <v-col>
                  <v-row align="center" fill-height>
                    <v-col md="3" cols="6">
                      <CardStatNumber
                        :number="stats.numberOfReviewing"
                        title="審核中"
                      ></CardStatNumber>
                    </v-col>
                    <v-col md="3" cols="6">
                      <CardStatNumber
                        :number="stats.numberOfApproved"
                        title="已通過"
                      ></CardStatNumber>
                    </v-col>
                    <v-col md="3" cols="6">
                      <CardStatNumber
                        :number="stats.numberOfInsufficient"
                        title="待補件"
                      ></CardStatNumber>
                    </v-col>
                    <v-col md="3" cols="6">
                      <CardStatNumber
                        :number="stats.numberOfRejected"
                        title="已退回"
                      ></CardStatNumber>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-card class="px-1 py-1">
            <v-card-text>
              <p class="text-h5 text--primary">啟用狀態</p>
              <v-row align="center" fill-height>
                <v-col cols="6">
                  <CardStatNumber
                    :number="stats.numberOfActive"
                    title="啟用"
                  ></CardStatNumber>
                </v-col>
                <v-col cols="6">
                  <CardStatNumber
                    :number="stats.numberOfInactive"
                    title="停用"
                  ></CardStatNumber>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedReviewStatus"
                    :items="reviewOptions"
                    item-text="title"
                    item-value="value"
                    label="審核狀態"
                    :disable="!isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedReviewStatus = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedCategoryId"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="商店類型"
                    :disable="!isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedCategoryId = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="2"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="
                        pagination.page = 1;
                        getStoreList();
                      "
                      :disabled="!isLoaded"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              特店列表
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                :label="$t(`common['Search']`)"
                single-line
                hide-details
              ></v-text-field>
              <!-- <span class="pt-5">
                <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  @click="onEditItem(null)"
                >
                  {{ $t(`common["Add"]`) }}
                </v-btn>
              </span> -->
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="tableHeaders"
                :items="tableData"
                :loading="!isLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :search="search"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="id"
                :show-expand="false"
                @click:row="showExpanded"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <!-- <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">聯絡人</th>
                            <th class="text-left">聯絡人電話</th>
                            <th class="text-left">聯絡人電子郵箱</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ item.contactName }}</td>
                            <td>{{ item.contactPhone }}</td>
                            <td>{{ item.contactEmail }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template> -->
                <template v-slot:item.banId="{ item }">
                  <p>
                    {{ censorText(item.banId) }}
                  </p>
                </template>
                <template v-slot:item.contactName="{ item }">
                  <p>
                    {{ censorText(item.contactName) }}
                  </p>
                </template>
                <template v-slot:item.contactInfo="{ item }">
                  <p>
                    <!-- <v-icon>fas fa-phone</v-icon> -->
                    {{ censorText(item.contactPhone) }}
                  </p>
                  <p>
                    <!-- <v-icon>fas fa-envelope</v-icon> -->{{
                      censorText(item.contactEmail)
                    }}
                  </p>
                </template>
                <template v-slot:item.unclosingBalance="{ item }">
                  <span>
                    {{ formatNumber(item.unclosingBalance, 0) }}
                  </span>
                </template>
                <template v-slot:item.closingBalance="{ item }">
                  <span> {{ formatNumber(item.closingBalance, 0) }} </span>
                </template>
                <template v-slot:item.withdrawBalance="{ item }">
                  <span> {{ formatNumber(item.withdrawBalance, 0) }} </span>
                </template>
                <template v-slot:item.storeStatus="{ item }">
                  <!-- <span v-if="item.storeStatus === 1"> 啟用 </span>
                  <span v-else> 停用 </span> -->
                  <v-row class="mx-auto">
                    <v-switch
                      v-model="item.storeStatus"
                      inset
                      color="green"
                      :false-value="0"
                      :true-value="1"
                      :disabled="!canAudit"
                      @change="onToggleStoreStatus(item)"
                    ></v-switch>
                  </v-row>
                </template>
                <template v-slot:item.approveStatus="{ item }">
                  <span v-if="item.approveStatus === 'approved'"> 核准 </span>
                  <span v-else-if="item.approveStatus === 'insufficient'">
                    待補件
                  </span>
                  <span v-else-if="item.approveStatus === 'rejected'">
                    退回
                  </span>
                  <span v-else> 審核中 </span>
                  <v-tooltip bottom v-if="canAudit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="teal"
                        v-on="on"
                        :disabled="
                          item.approveStatus === 'approved' ||
                          item.approveStatus === 'rejected'
                        "
                        v-if="
                          item.approveStatus !== 'approved' &&
                          item.approveStatus !== 'rejected'
                        "
                        @click="onApproveClick(item)"
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>審核</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.createdAt="{ item }">
                  <span>{{
                    item.createdAt
                      ? moment
                          .unix(item.createdAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <span>{{
                    item.updatedAt
                      ? moment
                          .unix(item.updatedAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.actions="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="blue darken-2"
                        v-on="on"
                        @click="onEditItem(props.item, 'view')"
                      >
                        <v-icon>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>查看詳細內容</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="pink darken-2"
                        v-on="on"
                        @click="getQrcodeUrl(props.item)"
                      >
                        <v-icon>fas fa-qrcode</v-icon>
                      </v-btn>
                    </template>
                    <span>QRCode</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }" v-if="canEditManager">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="purple darken-2"
                        v-on="on"
                        @click="onInviteClick(props.item)"
                      >
                        <v-icon>fas fa-user-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>新增/邀請管理人員</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        color="primary darken-2"
                        v-on="on"
                        @click="
                          selectedStoreId = props.item.id;
                          openDetailDialog = true;
                        "
                      >
                        <v-icon>fas fa-th-list</v-icon>
                      </v-btn>
                    </template>
                    <span>查看交易明細</span>
                  </v-tooltip> -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="green darken-3"
                        v-on="on"
                        @click="
                          selectedStoreId = props.item.id;
                          openChartDialog = true;
                        "
                      >
                        <v-icon>fas fa-chart-line</v-icon>
                      </v-btn>
                    </template>
                    <span>查看交易明細</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }" v-if="canEdit">
                      <!-- <v-btn
                        class="mx-2  mt-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.approveStatus === 'reviewing' ||
                          props.item.approveStatus === 'insufficient'
                            ? 'cyan'
                            : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.approveStatus === 'reviewing' ||
                            props.item.approveStatus === 'insufficient'
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.approveStatus === 'reviewing' ||
                          props.item.approveStatus === 'insufficient'
                            ? onEditItem(props.item)
                            : ''
                        "
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn> -->
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="cyan"
                        v-on="on"
                        @click="onEditItem(props.item)"
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>編輯</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="props.item.approveStatus === 'approved'"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="orange darken-3"
                        v-on="on"
                        @click="sendOpenEmail(props.item)"
                      >
                        <v-icon>fas fa-envelope</v-icon>
                      </v-btn>
                    </template>
                    <span>寄送開通信</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="tableData.length"
                :loading="!isLoaded"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="openApproveDialog" persistent max-width="600px">
        <v-card v-if="openApproveDialog">
          <v-card-title>
            <span class="headline">審核特店</span>
            <span class="subtitle"> - {{ approveItem.storeName }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="approveForm">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-radio-group
                      v-model="reviewStatus"
                      row
                      mandatory
                      class="large-radio"
                    >
                      <v-radio
                        label="核准"
                        value="approved"
                        :color="reviewStatus === 'approved' ? '#1867c0' : ''"
                      ></v-radio>
                      <v-radio
                        label="補件"
                        value="insufficient"
                        :color="
                          reviewStatus === 'insufficient' ? '#1867c0' : ''
                        "
                      ></v-radio>
                      <v-radio
                        label="退回"
                        value="rejected"
                        :color="reviewStatus === 'rejected' ? '#1867c0' : ''"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="comment"
                      outlined
                      label="簽核意見"
                      :rules="rules.required"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!-- <v-btn color="blue darken-1" text @click="uploadImage">Upload</v-btn> -->
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeApproveDialog">
              {{ $t(`common["Cancel"]`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onApprove">
              {{ $t(`common["Save"]`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openEditDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ $t(`common["Store"]`) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.storeCategoryId"
                      :items="categories"
                      item-text="name"
                      item-value="id"
                      label="商店類型"
                      :disabled="viewOnly"
                      :rules="rules.required"
                    >
                    </v-select>
                    <!-- <v-text-field
                      v-model="editItem.storeCategoryName"
                      label="特店類型"
                      :disabled="viewOnly"
                    ></v-text-field> -->
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.banId"
                      label="統一編號"
                      :disabled="viewOnly"
                      :rules="[rules.ban(editItem.banId)]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.businessName"
                      label="營業登記名稱"
                      :disabled="viewOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.storeName"
                      label="招牌名稱"
                      :rules="rules.required"
                      :disabled="viewOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.address"
                      label="營業地址"
                      :disabled="viewOnly"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.phone"
                      label="營業電話"
                      :disabled="viewOnly"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.ownerName"
                      label="負責人姓名"
                      :disabled="viewOnly"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.contactName"
                      label="聯絡人姓名"
                      :disabled="viewOnly"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.contactPhone"
                      label="聯絡人手機號碼"
                      :disabled="viewOnly"
                      :rules="[rules.phone(editItem.contactPhone, 'TW')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.contactEmail"
                      label="聯絡人電子郵箱"
                      :disabled="viewOnly"
                      :rules="rules.email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <GoogleMap
                      v-if="openEditDialog"
                      :data="googleStore"
                      :editable="!viewOnly"
                      @change="onGStoreChange"
                    ></GoogleMap>
                    <!-- <v-text-field
                      ref="pac-input"
                      id="pac-input"
                      type="text"
                      placeholder="搜尋店名"
                      v-model="editItem.googleStore"
                      :disabled="viewOnly"
                    />
                    <div ref="map" class="map" id="map"></div>
                    <ol>
                      <li class="fS gray">
                        在地圖上方輸入框中輸入您的商店名稱
                      </li>
                      <li class="fS gray">在出現的列表中點擊您的商店名稱</li>
                    </ol> -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <!-- <v-text-field
                      v-model="editItem.bankCode"
                      label="銀行代碼"
                      :disabled="viewOnly"
                    ></v-text-field> -->
                    <v-select
                      v-model="editItem.bankCode"
                      :items="bankList"
                      item-text="name"
                      item-value="bankCode"
                      label="銀行代碼"
                      :disabled="viewOnly"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.bankCode }} - {{ data.item.name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.bankCode }} - {{ data.item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.branchCode"
                      label="分行代碼"
                      :disabled="viewOnly"
                    ></v-text-field>
                    <!-- <v-select
                      v-model="editItem.branchCode"
                      :items="branchList"
                      item-text="name"
                      item-value="branchCode"
                      label="分行代碼"
                      :disabled="viewOnly"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.branchCode }} - {{ data.item.name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.branchCode }} - {{ data.item.name }}
                      </template>
                    </v-select> -->
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.accountNumber"
                      label="銀行帳號"
                      :disabled="viewOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.accountName"
                      label="銀行戶名 "
                      :disabled="viewOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.idNo"
                      label="統一編號/身份證號 "
                      :disabled="viewOnly"
                      :rules="[rules.idNo(editItem.idNo)]"
                    ></v-text-field>
                    <!-- <v-text-field
                      v-model="editItem.idNo"
                      label="統一編號/身份證號 "
                      :disabled="viewOnly"
                      :rules="[rules.idNo(editItem.idNo)]"
                    ></v-text-field> -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" v-if="!viewOnly">
                    <v-row>
                      <v-col cols="6">
                        <v-file-input
                          accept="image/*"
                          label="存摺封面"
                          v-model="uploadBankBookImg"
                          @change="onImageFileChange('bankbook')"
                          :disabled="isProcessing"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="6">
                        <v-file-input
                          accept="image/*"
                          label="統編圖檔"
                          v-model="uploadBanImg"
                          @change="onImageFileChange('ban')"
                          :disabled="isProcessing"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    v-if="
                      viewOnly || uploadBankBookImg || editItem.bankbookImageUrl
                    "
                    cols="12"
                  >
                    <v-row>
                      <v-col cols="6">
                        <div class="subheading">
                          存摺封面
                          <a
                            v-if="viewOnly && editItem.bankbookImageUrl"
                            :href="editItem.bankbookImageUrl"
                            target="_blank"
                          >
                            （查看全圖）
                          </a>
                        </div>
                        <v-img
                          v-if="uploadBankBookImg"
                          :contain="true"
                          :width="300"
                          :max-height="300"
                          :src="uploadBankBookImgUrl"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                                :size="30"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-img
                          v-else
                          :contain="true"
                          :width="300"
                          :max-height="300"
                          :src="editItem.bankbookImageUrl"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                                :size="30"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col cols="6">
                        <div class="subheading">
                          統一編號章
                          <a
                            v-if="viewOnly && editItem.banImageUrl"
                            :href="editItem.banImageUrl"
                            target="_blank"
                          >
                            （查看全圖）
                          </a>
                        </div>
                        <v-img
                          v-if="uploadBanImg"
                          :contain="true"
                          :width="300"
                          :max-height="300"
                          :src="uploadBanImgUrl"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                                :size="30"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-img
                          v-else
                          :contain="true"
                          :width="300"
                          :max-height="300"
                          :src="editItem.banImageUrl"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                                :size="30"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="12" sm="6">
                    <div class="subheading">存摺封面</div>
                    <v-img
                      :src="editItem.bankbookImageUrl"
                      aspect-ratio="1.7"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <div class="subheading">統一編號章</div>
                    <v-img
                      :src="editItem.banImageUrl"
                      aspect-ratio="1.7"
                    ></v-img>
                  </v-col>
                </v-row> -->
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn
              v-if="!viewOnly"
              color="blue darken-1"
              text
              @click="onSaveEditItem"
            >
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openQrcodeDialog" max-width="400px">
        <v-card>
          <v-card-title class="justify-center">
            <v-row class="text-center align-center justify-center">
              <v-col cols="12">
                <span class="text-h3" v-if="selectedStore">
                  {{ selectedStore.storeName }}
                </span>
              </v-col>
              <v-col cols="12">
                <span class="text-h4" v-if="selectedStore">
                  {{ selectedStore.storeCode }}
                </span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="text-center pt-10" v-if="!isQrcodeLoaded">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div class="d-flex align-center">
                <v-img
                  width="200"
                  :src="qrcodeUrl"
                  :lazy-src="qrcodeUrl"
                  @load="onLoadedQrcode"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="black"
                        :size="50"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeQrcodeDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openInviteDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">新增/邀請管理人員</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="permissionRole"
                    :items="permissionRoles"
                    item-text="title"
                    item-value="value"
                    label=""
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pt-6">
                  <v-btn
                    class="ms-3"
                    dark
                    medium
                    :color="permissionRole ? 'blue darken-2' : '#DBDCDC'"
                    :style="{
                      cursor: permissionRole ? 'pointer' : 'not-allowed',
                    }"
                    @click="permissionRole ? generateInviteUrl() : null"
                  >
                    產生邀請連結
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="9">
                  <v-text-field
                    v-model="inviteUrl"
                    type="text"
                    label="邀請連結"
                    :disabled="true"
                  ></v-text-field>
                </v-col>

                <v-col cols="3" class="pt-6">
                  <v-btn
                    class="ms-3"
                    dark
                    medium
                    :color="inviteUrl ? 'blue darken-2' : '#DBDCDC'"
                    :style="{
                      cursor: inviteUrl ? 'pointer' : 'not-allowed',
                    }"
                    @click="inviteUrl ? copyInviteUrl() : null"
                  >
                    <!-- <v-icon>fa-lg fa-copy</v-icon> -->
                    複製邀請連結
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeInviteDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="openDetailDialog"
        max-width="800px"
        no-click-animation
        persistent
        fullscreen
      >
        <Transactions
          v-if="openDetailDialog"
          entity-type="S"
          :store-id="selectedStoreId"
          @close="openDetailDialog = false"
        ></Transactions>
      </v-dialog>
      <v-dialog
        v-model="openChartDialog"
        max-width="800px"
        no-click-animation
        persistent
        fullscreen
      >
        <TrendChart
          entity-type="S"
          v-if="openChartDialog"
          :store-id="selectedStoreId"
          @close="openChartDialog = false"
        ></TrendChart>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import {
  fetchStores,
  fetchStoreCategories,
  fetchBankList,
  fetchStoreStats,
  // fetchBankBranchList,
} from "src/apis/fetchData";
import {
  approveStore,
  generateInviteUrl,
  activeStore,
  deactiveStore,
  sendStoreOpenEmail,
} from "src/apis/updateData";
import {
  formatNumber,
  isBanFormat,
  isIdFormat,
  checkPermission,
} from "src/util/utils";
import Transactions from "./Widgets/Transactions.vue";
import TrendChart from "./Widgets/TrendChart.vue";
import GoogleMap from "./Widgets/GoogleMap.vue";
import { updateStore } from "src/apis/storesCore";
import { isValidPhoneNumber } from "libphonenumber-js";
import Pagination from "../Campaigns/Widgets/Pagination.vue";
import CardStatNumber from "./Widgets/CardStatNumber.vue";
import { censorName } from "src/util/utils";

export default {
  name: "StoreList",
  components: {
    Transactions,
    TrendChart,
    GoogleMap,
    Pagination,
    CardStatNumber,
  },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      selectedStoreId: null,
      reviewOptions: [
        {
          value: "reviewing",
          title: "審核中",
        },
        {
          value: "approved",
          title: "核准",
        },
        {
          value: "insufficient",
          title: "待補件",
        },
        {
          value: "rejected",
          title: "退回",
        },
      ],
      selectedReviewStatus: null,
      isLoaded: true,
      isNew: false,
      viewOnly: false,
      openApproveDialog: false,
      openEditDialog: false,
      categories: [],
      isCategoryLoaded: true,
      selectedCategoryId: null,
      singleExpand: true,
      expanded: [],
      tableData: [],
      tableHeaders: [
        {
          text: "ID",
          sortable: true,
          value: "id",
        },
        // {
        //   text: "營業登記名稱",
        //   sortable: true,
        //   value: "businessName",
        // },
        {
          text: "招牌名稱",
          sortable: true,
          value: "storeName",
        },
        {
          text: "類別",
          sortable: true,
          value: "storeCategoryName",
        },
        {
          text: "統編 ",
          sortable: true,
          value: "banId",
        },
        {
          text: "聯絡人",
          sortable: true,
          value: "contactName",
        },
        {
          text: "聯絡資訊",
          sortable: false,
          value: "contactInfo",
        },
        // {
        //   text: "聯絡郵箱",
        //   sortable: false,
        //   value: "contactEmail",
        // },
        {
          text: "地址",
          sortable: false,
          value: "address",
        },
        {
          text: "特店狀態",
          sortable: true,
          filterable: false,
          value: "storeStatus",
        },
        {
          text: "審核狀態",
          sortable: true,
          filterable: false,
          value: "approveStatus",
        },
        {
          text: "未結點數",
          sortable: true,
          filterable: false,
          align: "right",
          value: "unclosingBalance",
        },
        {
          text: "已結點數",
          sortable: true,
          filterable: false,
          align: "right",
          value: "closingBalance",
        },
        {
          text: "累積請款",
          sortable: true,
          filterable: false,
          align: "right",
          value: "withdrawBalance",
        },
        {
          text: "建立時間",
          sortable: true,
          filterable: false,
          value: "createdAt",
        },
        {
          text: "更新時間",
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },
        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      reviewStatus: null,
      comment: null,
      approveItem: null,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        email: [
          (v) => !!v || this.$t(`common["Required"]`),
          (v) =>
            !v ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            this.$t(`validation["Incorrect Format"]`),
        ],
        phone() {
          return true;
        },
        ban() {
          return true;
        },
        idNo() {
          return true;
        },
      },
      defaultEditItem: {
        name: null,
      },
      editItem: {
        name: null,
      },
      bankList: [],
      // branchList: [],
      selectedStore: null,
      qrcodeOptions: {
        chs: "300x300",
        cht: "qr",
        chl: null,
        chld: "M|1",
      },
      qrcodeUrl: null,
      openQrcodeDialog: false,
      openInviteDialog: false,
      permissionRole: null,
      permissionRoles: [
        {
          value: "1", // "Administrator",
          title: "管理員",
        },
        {
          value: "3", // "Accountant",
          title: "帳務人員",
        },
        {
          value: "2", // "Employee",
          title: "操作人員",
        },
      ],
      inviteUrl: null,
      openDetailDialog: false,
      openChartDialog: false,
      isQrcodeLoaded: false,
      uploadBanImg: null,
      uploadBankBookImg: null,
      uploadBanImgUrl: null,
      uploadBankBookImgUrl: null,
      isProcessing: false,
      googleStore: {},
      stats: {
        numberOfReviewing: 0,
        numberOfApproved: 0,
        numberOfInsufficient: 0,
        numberOfRejected: 0,
        numberOfActive: 0,
        numberOfInactive: 0,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
      permissions: (state) => state.userPermissions,
    }),
    isActionDisabled() {
      return !(this.isPeriodsLoaded && this.isBudgetsLoaded);
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:StoreManage",
          "EditStores",
          true
        );
      }
      return false;
    },
    canAudit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:StoreManage",
          "AuditStores",
          true
        );
      }
      return false;
    },
    canEditManager() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:StoreManage",
          "EditStoresManagers",
          true
        );
      }
      return false;
    },
  },
  watch: {},
  created() {
    this.selectedCategoryId = this.$route.query.categoryId
      ? parseInt(this.$route.query.categoryId)
      : null;
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.init();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.init();
    }
    this.rules.phone = (v, country) => {
      if (!v) {
        return this.$t(`common["Required"]`);
      } else {
        return (
          isValidPhoneNumber(v, country) ||
          this.$t(`validation["Incorrect Format"]`)
        );
      }
    };
    this.rules.ban = (v) => {
      if (!v) {
        return this.$t(`common["Required"]`);
      } else {
        return isBanFormat(v) || this.$t(`validation["Incorrect Format"]`);
      }
    };
    this.rules.idNo = (v) => {
      if (v) {
        return (
          isBanFormat(v) ||
          isIdFormat(v) ||
          this.$t(`validation["Incorrect Format"]`)
        );
      } else {
        return true;
      }
      // if (!v) {
      //   return this.$t(`common["Required"]`);
      // } else {
      //   return (
      //     isBanFormat(v) ||
      //     isIdFormat(v) ||
      //     this.$t(`validation["Incorrect Format"]`)
      //   );
      // }
    };
  },
  methods: {
    censorText(text) {
      return text && text.length ? censorName(text) : "";
    },
    showExpanded(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
    init() {
      this.getStoreCategories();
      this.getStoreList();
      this.getBankList();
      this.getStoreStats();
    },
    getStoreStats() {
      fetchStoreStats(this.merchantId, null)
        .then((res) => {
          console.log("fetchStoreStats done", res);
          this.stats = res.data;
        })
        .catch((e) => {
          console.log("fetchStoreStats failed", e);
        });
    },
    // initMap() {
    //   console.log("-----", this.$el.querySelector(".map"));
    //   const map = new google.maps.Map(document.getElementById("map"), {
    //     center: { lat: 24.9742352, lng: 121.0703047 },
    //     zoom: 13,
    //     mapTypeId: "roadmap",
    //   });
    //   // Create the search box and link it to the UI element.
    //   const input = document.getElementById("pac-input");

    //   const searchBox = new google.maps.places.SearchBox(input);
    //   map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    //   // Bias the SearchBox results towards current map's viewport.
    //   map.addListener("bounds_changed", () => {
    //     searchBox.setBounds(map.getBounds());
    //   });

    //   let markers = [];

    //   // Listen for the event fired when the user selects a prediction and retrieve
    //   // more details for that place.
    //   searchBox.addListener("places_changed", () => {
    //     const places = searchBox.getPlaces();
    //     console.log(places);

    //     if (places.length == 0) {
    //       return;
    //     }

    //     // Clear out the old markers.
    //     markers.forEach((marker) => {
    //       marker.setMap(null);
    //     });
    //     markers = [];

    //     // For each place, get the icon, name and location.
    //     const bounds = new google.maps.LatLngBounds();

    //     places.forEach((place) => {
    //       if (!place.geometry || !place.geometry.location) {
    //         console.log("Returned place contains no geometry");
    //         return;
    //       }

    //       Object.assign(this.googleStore, {
    //         googleStore: place.name,
    //       });
    //       Object.assign(this.googleStore, {
    //         storePlaceId: place.place_id,
    //       });
    //       Object.assign(this.googleStore, {
    //         geometry: JSON.stringify(place.geometry),
    //       });

    //       const icon = {
    //         url: place.icon,
    //         size: new google.maps.Size(71, 71),
    //         origin: new google.maps.Point(0, 0),
    //         anchor: new google.maps.Point(17, 34),
    //         scaledSize: new google.maps.Size(25, 25),
    //       };

    //       // Create a marker for each place.
    //       markers.push(
    //         new google.maps.Marker({
    //           map,
    //           icon,
    //           title: place.name,
    //           position: place.geometry.location,
    //         })
    //       );
    //       console.log({
    //         map,
    //         icon,
    //         title: place.name,
    //         position: place.geometry.location,
    //       });
    //       if (place.geometry.viewport) {
    //         // Only geocodes have viewport.
    //         bounds.union(place.geometry.viewport);
    //       } else {
    //         bounds.extend(place.geometry.location);
    //       }
    //     });
    //     map.fitBounds(bounds);
    //     map.setZoom(16);
    //     // map.setCenter(markers.getPosition())
    //   });
    // },
    getBankList() {
      fetchBankList(this.merchantId)
        .then((res) => {
          console.log("fetchBankList done", res);
          this.bankList = [...res.data];
        })
        .catch((e) => {
          console.log("fetchBankList failed", e);
        })
        .finally(() => {});
    },
    // getBankBranchList(bankId) {
    //   fetchBankBranchList(this.merchantId, bankId)
    //     .then((res) => {
    //       console.log("fetchBankBranchList done", res);
    //       this.branchList = [...res.data];
    //     })
    //     .catch((e) => {
    //       console.log("fetchBankBranchList failed", e);
    //     })
    //     .finally(() => {});
    // },
    getStoreList() {
      this.isLoaded = false;
      let query = {
        approved: this.selectedReviewStatus,
        categoryId: this.selectedCategoryId,
      };
      // this.pagination.page = 1;
      fetchStores(this.merchantId, query)
        .then((res) => {
          console.log("fetchStores done", res);
          this.tableData = [...res.data];
        })
        .catch((e) => {
          console.log("fetchPointSettings failed", e);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    getStoreCategories() {
      this.isCategoryLoaded = false;
      let query = {
        approved: this.selectedReviewStatus,
      };
      fetchStoreCategories(this.merchantId, query)
        .then((res) => {
          console.log("fetchStoreCategories done", res);
          this.categories = [...res.data];
        })
        .catch((e) => {
          console.log("fetchStoreCategories failed", e);
        })
        .finally(() => {
          this.isCategoryLoaded = true;
        });
    },
    getQrcodeUrl(store) {
      if (!store.storeCode) {
        this.$swal({
          title: "",
          html: "查無特店代碼",
          type: "error",
          showCancelButton: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }
      this.selectedStore = store;
      this.qrcodeUrl = `https://tools.iiot.io/barcode/qrcode?text=S:${store.storeCode}&height=15`;
      // this.qrcodeOptions.chl = `S:${store.storeCode}`;
      // let data = null;
      // Object.entries(this.qrcodeOptions).forEach(([key, value]) => {
      //   if (data) {
      //     data += `&${key}=${value}`;
      //   } else {
      //     data = `${key}=${value}`;
      //   }
      // });
      // this.qrcodeUrl = `https://chart.googleapis.com/chart?${data}`;
      this.openQrcodeDialog = true;
    },
    closeQrcodeDialog() {
      this.openQrcodeDialog = false;
      this.selectedStore = null;
      this.qrcodeUrl = null;
      this.qrcodeOptions.chl = null;
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.uploadBanImg = null;
      this.uploadBanImgUrl = null;
      this.uploadBankBookImg = null;
      this.uploadBankBookImgUrl = null;
      this.openEditDialog = false;
    },
    closeApproveDialog() {
      this.$refs.approveForm.resetValidation();
      this.$refs.approveForm.reset();
      this.openApproveDialog = false;
    },
    onApproveClick(item) {
      console.log("on approve click:" + JSON.stringify(item));
      this.approveItem = item;
      this.openApproveDialog = true;
    },
    onApprove() {
      console.log("approve budget:" + JSON.stringify(this.approveItem));
      if (this.$refs.approveForm) {
        const valid = this.$refs.approveForm.validate();
        if (valid) {
          const data = {
            approver: this.ssoUser.userId,
            approveStatus: this.reviewStatus,
            comment: this.comment,
            sendOpenEmail: true,
          };
          approveStore(this.merchantId, this.approveItem.id, data)
            .then((res) => {
              console.log("approveStatus done", res);
              this.approveItem.approveStatus = data.approveStatus;
              this.approveItem.comment = data.comment;
              this.approveItem.storeStatus = res.data.storeStatus;

              this.comment = null;
              this.reviewStatus = null;
              this.openApproveDialog = false;
              this.$refs.approveForm.resetValidation();
              this.$refs.approveForm.reset();
            })
            .catch((e) => {
              console.log("approveStore failed", e.response);
              let errmsg = "無法更新特店審核結果";
              if (e.response && e.response.data && e.response.data.message) {
                errmsg += "<br/>" + e.response.data.message;
              }
              this.isNew = false;
              this.showErrorAlert(errmsg);
            });
        } else {
          this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
        }
      }
    },
    onEditItem(item, type) {
      if (item) {
        this.editItem = Object.assign({}, item);
        this.googleStore.storePlaceId = this.editItem.storePlaceId;
        this.googleStore.geometry = this.editItem.geometry;
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        this.isNew = true;
      }
      if (type === "view") {
        this.viewOnly = true;
      } else {
        this.viewOnly = false;
      }
      // if (this.editItem.bankCode) {
      //   this.getBankBranchList(this.editItem.bankCode);
      // }
      this.openEditDialog = true;
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      if (valid) {
        console.log("save edit item:" + JSON.stringify(this.editItem));
        this.isProcessing = false;
        let formData = new FormData();
        if (this.uploadBanImg) {
          formData.append("banImage", this.uploadBanImg);
        }
        if (this.uploadBankBookImg) {
          formData.append("bankBookImage", this.uploadBankBookImg);
        }
        if (this.editItem.approveStatus) {
          formData.append("approveStatus", this.editItem.approveStatus);
        }
        formData.append("memberId", this.ssoUser.userId);
        formData.append("merchantId", this.merchantId);
        formData.append("name", this.editItem.storeName);
        const fields = [
          "banId",
          "businessName",
          "phone",
          "address",
          "storeCategoryId",
          "ownerName",
          "contactName",
          "contactPhone",
          "contactEmail",
          "storePlaceId",
          "googleStore",
          "geometry",
          "bankCode",
          "branchCode",
          "accountName",
          "accountNumber",
          "idNo",
        ];
        fields.forEach((i) => {
          // will convert null to string
          formData.append(i, this.editItem[i] ? this.editItem[i] : "");
        });
        const storeId = this.editItem.id;
        updateStore(this.merchantId, storeId, formData)
          .then((res) => {
            console.log("updateStore done", res);
            setTimeout(this.getStoreList(), 0);
            this.closeEditDialog();
          })
          .catch((e) => {
            console.log("updateStore failed", e.response);
            let errmsg = "無法更新特店申請";
            if (
              e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message
            ) {
              errmsg += "<br/>" + e.response.data.error.message;
            }
            this.showErrorAlert(errmsg);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onInviteClick(store) {
      this.selectedStore = store;
      this.openInviteDialog = true;
    },
    generateInviteUrl() {
      let data = {
        permissionRole: [this.permissionRole],
      };
      generateInviteUrl(this.merchantId, this.selectedStore.id, data).then(
        (res) => {
          console.log("generateInviteUrl done", res);
          this.inviteUrl = res.data.url;
        }
      );
    },
    closeInviteDialog() {
      this.selectedStore = null;
      this.permissionRole = null;
      this.inviteUrl = null;
      this.openInviteDialog = false;
    },
    copyInviteUrl() {
      const text = this.inviteUrl;
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    onLoadedQrcode($event) {
      console.log("onLoadedQrcode", $event);
      this.isQrcodeLoaded = true;
    },
    onImageFileChange(type) {
      if (type === "bankbook") {
        this.uploadBankBookImgUrl = null;
        if (this.uploadBankBookImg) {
          this.uploadBankBookImgUrl = URL.createObjectURL(
            this.uploadBankBookImg
          );
        }
      } else if (type === "ban") {
        this.uploadBanImgUrl = null;
        if (this.uploadBanImg) {
          this.uploadBanImgUrl = URL.createObjectURL(this.uploadBanImg);
        }
      }
    },
    onGStoreChange(place) {
      Object.assign(this.editItem, {
        googleStore: place.name,
      });
      Object.assign(this.editItem, {
        storePlaceId: place.place_id,
      });
      Object.assign(this.editItem, {
        geometry: JSON.stringify(place.geometry),
      });
      // console.log("----on change-----", this.editItem);
    },
    onToggleStoreStatus(item) {
      let toggleStore = deactiveStore;
      if (item.storeStatus) {
        toggleStore = activeStore;
      }

      toggleStore(this.merchantId, item.id, null)
        .then(() => {
          console.log("done toggle store status.");
        })
        .catch((e) => {
          console.log("change store status failed:", e);
          this.$swal({
            title: "",
            html: "變更特店狀態失敗",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          return;
        });
    },
    sendOpenEmail(item) {
      let data = {
        storeName: item.storeName,
      };
      this.$swal({
        title: "寄送開通信",
        html: `
        請問您是否確定要寄送<b>${item.storeName}</b>特店開通信？
        <br/>
        收件者：${item.contactEmail}
        `,
        type: "success",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        cancelButtonText: this.$i18n.t(`common["Cancel"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          sendStoreOpenEmail(this.merchantId, item.id, data)
            .then(() => {
              this.$swal({
                title: "",
                html: `<b>${item.storeName}</b> <br/><br/>特店開通信已寄出`,
                type: "success",
                showCancelButton: false,
                confirmButtonText: this.$i18n.t(`common["Confirm"]`),
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                  cancelButton: "btn bg-gradient-danger",
                },
              });
            })
            .catch((e) => {
              console.log("send open email failed:", e);
              this.$swal({
                title: "",
                html: `<b>${item.storeName}</b> <br/><br/>特店開通信寄送失敗，請稍後重試`,
                type: "error",
                showCancelButton: false,
                confirmButtonText: this.$i18n.t(`common["Confirm"]`),
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                  cancelButton: "btn bg-gradient-danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
.map {
  height: 250px;
}

#description {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  font-family: Roboto;
  padding: 0;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  top: 5px !important;
  left: 5px !important;
  z-index: 9 !important;
  border-radius: 20px !important;
  height: 50px;
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  padding: 0 10px;
  text-overflow: ellipsis;
  width: calc(100% - 80px);
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

#target {
  width: 345px;
}
</style>
