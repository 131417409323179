var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"blue darken-2"}},[_c('v-toolbar-title',[_vm._v("收支明細")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"fab":"","plain":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" fas fa-times")])],1)],1)],1),_c('v-card-title',{staticClass:"justify-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"label":_vm.$t("points['Name']"),"items":_vm.availablePoints,"item-text":"name","item-value":"id","required":"","disabled":_vm.isDetailLoading,"no-data-text":_vm.$t("common['No Data']")},model:{value:(_vm.selectedPointId),callback:function ($$v) {_vm.selectedPointId=$$v},expression:"selectedPointId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"搜尋電話","single-line":"","hide-details":"","disabled":_vm.isDetailLoading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","name":"startDate","disabled":_vm.isDetailLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"開始日期","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}]),model:{value:(_vm.showStartPicker),callback:function ($$v) {_vm.showStartPicker=$$v},expression:"showStartPicker"}},[_c('v-date-picker',{attrs:{"color":"blue-grey lighten-1"},on:{"input":function($event){_vm.showStartPicker = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","name":"endDate","disabled":_vm.isDetailLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"結束日期","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}]),model:{value:(_vm.showEndPicker),callback:function ($$v) {_vm.showEndPicker=$$v},expression:"showEndPicker"}},[_c('v-date-picker',{attrs:{"color":"blue-grey lighten-1"},on:{"input":function($event){_vm.showEndPicker = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{class:_vm.$vuetify.breakpoint.xs ? '' : 'pt-4',attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"\n            font-weight-normal\n            text-capitalize\n            ma-2\n            white--text\n            py-3\n            px-6\n            ms-3\n          ",attrs:{"color":"blue-grey","disabled":_vm.isDetailLoading,"loading":_vm.isDetailLoading},on:{"click":function($event){_vm.pagination.page = 1;
            _vm.getDataFromApi();}}},[_vm._v(" "+_vm._s(_vm.$t("common[\"Search\"]"))+" ")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableDetailHeaders,"items":_vm.details,"options":_vm.pagination,"server-items-length":_vm.totalDetails,"loading":_vm.isDetailLoading,"loading-text":_vm.$t("common['Loading... Please wait']"),"no-data-text":_vm.$t("common['No Data']"),"no-results-text":_vm.$t("common['No Data']"),"footer-props":{
        'items-per-page-text': _vm.$t("common['Rows per page']"),
      },"page":_vm.pagination.page,"hide-default-footer":"","items-per-page":_vm.pagination.itemsPerPage},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.pointAmount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.pointAmount, 0))+" ")]}},{key:"item.orderStatus",fn:function(ref){
      var item = ref.item;
return [(item.orderStatus === 'confirmed')?_c('span',[_vm._v(" 已成立")]):_c('span',[_vm._v(" 已取消")])]}},{key:"item.settlementStatus",fn:function(ref){
      var item = ref.item;
return [(item.settlementStatus === 'open')?_c('span',[_vm._v(" 未請款")]):(item.settlementStatus === 'closed')?_c('span',[_vm._v(" 已結算")]):(item.settlementStatus === 'withdrawing')?_c('span',[_vm._v(" 請款中 ")]):_c('span',[_vm._v(" 已付款 ")])]}},{key:"item.createdTs",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdTs ? _vm.moment.unix(item.createdTs).format("YYYY/MM/DD HH:mm:ss") : ""))])]}},{key:"item.updatedAt",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.item.orderStatus === "confirmed" ? _vm.moment.unix(props.item.createdTs).format("YYYY/MM/DD HH:mm:ss") : _vm.moment .unix(props.item.cancelledTs) .format("YYYY/MM/DD HH:mm:ss")))])]}}])})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('Pagination',{attrs:{"pagination":_vm.pagination,"total":_vm.totalDetails,"loading":_vm.isDetailLoading},on:{"update":_vm.getDataFromApi}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }