<template>
  <div>
    <label class="text-sm text-body">Google評價</label>
    <input
      ref="pac-input"
      id="pac-input"
      type="text"
      placeholder="搜尋店名"
      v-model="googleStore.googleStore"
      :disabled="!editable"
    />
    <div ref="map" class="map" id="map"></div>
    <ol>
      <li class="fS gray">在地圖上方輸入框中輸入您的商店名稱</li>
      <li class="fS gray">在出現的列表中點擊您的商店名稱</li>
    </ol>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "GoogleMap",
  components: {},
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      googleStore: {
        googleStore: null,
        storePlaceId: null,
        geometry: null,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
  },
  watch: {},
  created() {},
  mounted() {
    // this.googleStore = JSON.parse(JSON.stringify(this.data));
    this.initMap();
  },
  methods: {
    initMap() {
      // console.log("-----", this.$el.querySelector(".map"));
      const map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 24.9742352, lng: 121.0703047 },
        zoom: 13,
        mapTypeId: "roadmap",
      });
      // Create the search box and link it to the UI element.
      const input = document.getElementById("pac-input");

      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
      });

      let markers = [];

      console.log("initmap:", this.data);
      if (this.data.storePlaceId) {
        var config = {
          method: "get",
          url: `${process.env.VUE_APP_APIHOST}/apis/v1/place-detail`,
          // url: `https://maps.googleapis.com/maps/api/place/details/json?place_id=${this.googleStore.storePlaceId}&key=${process.env.VUE_APP_GOOGLE_PLACES_API_KEY}`,
          headers: {},
          params: {
            storePlaceId: this.data.storePlaceId,
          },
        };
        const self = this;
        axios(config)
          .then(function (response) {
            /// console.log(JSON.stringify(response.data));
            const place = response.data;
            const bounds = new google.maps.LatLngBounds();
            console.log("bounds:", bounds);
            // For each place, get the icon, name and location.
            const icon = {
              url: place.icon,
              size: new google.maps.Size(71, 71),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(17, 34),
              scaledSize: new google.maps.Size(25, 25),
            };

            // Create a marker for each place.
            markers.push(
              new google.maps.Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location,
              })
            );
            console.log({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            });
            Object.assign(self.googleStore, {
              googleStore: place.name,
            });
            Object.assign(self.googleStore, {
              storePlaceId: self.data.place_id,
            });
            Object.assign(self.googleStore, {
              geometry: JSON.stringify(place.geometry),
            });
            bounds.extend(place.geometry.location);
            // if (place.geometry.viewport) {
            //   // Only geocodes have viewport.
            //   bounds.union(place.geometry.viewport);
            // } else {
            //   bounds.extend(place.geometry.location);
            // }
            map.fitBounds(bounds);
            map.setZoom(16);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        console.log(places);

        if (places.length == 0) {
          return;
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }

          Object.assign(this.googleStore, {
            googleStore: place.name,
          });
          Object.assign(this.googleStore, {
            storePlaceId: place.place_id,
          });
          Object.assign(this.googleStore, {
            geometry: JSON.stringify(place.geometry),
          });

          const icon = {
            url: place.icon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };

          // Create a marker for each place.
          markers.push(
            new google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );
          console.log({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          });
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
          this.$emit("change", place);
        });
        map.fitBounds(bounds);
        map.setZoom(16);

        // map.setCenter(markers.getPosition())
      });
    },
  },
};
</script>
<style scoped>
.map {
  height: 250px;
}

#description {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  font-family: Roboto;
  padding: 0;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  top: 5px !important;
  left: 5px !important;
  z-index: 9 !important;
  border-radius: 20px !important;
  height: 50px;
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  padding: 0 10px;
  text-overflow: ellipsis;
  width: calc(100% - 80px);
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

#target {
  width: 345px;
}
</style>
