<template>
  <v-card>
    <v-toolbar dark color="blue darken-2">
      <v-toolbar-title>交易明細</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn fab plain @click="close">
          <v-icon> fas fa-times</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-title class="justify-center">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            v-model="selectedPointId"
            :label="$t(`points['Name']`)"
            :items="availablePoints"
            item-text="name"
            item-value="id"
            required
            :disabled="isDetailLoading"
            :no-data-text="$t(`common['No Data']`)"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="搜尋電話"
            single-line
            hide-details
            clearable
            :disabled="isDetailLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-menu
            v-model="showStartPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            name="startDate"
            :disabled="isDetailLoading"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="startDate"
                label="開始日期"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              color="blue-grey lighten-1"
              @input="showStartPicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2">
          <v-menu
            v-model="showEndPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            name="endDate"
            :disabled="isDetailLoading"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="endDate"
                label="結束日期"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              color="blue-grey lighten-1"
              @input="showEndPicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2" :class="$vuetify.breakpoint.xs ? '' : 'pt-4'">
          <v-btn
            color="blue-grey"
            class="
              font-weight-normal
              text-capitalize
              ma-2
              white--text
              py-3
              px-6
              ms-3
            "
            :disabled="isDetailLoading"
            :loading="isDetailLoading"
            @click="
              pagination.page = 1;
              getDataFromApi(true);
            "
          >
            {{ $t(`common["Search"]`) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="2" cols="12">
          <v-row>
            <v-col cols="12">
              <CardStatNumber
                :number="total.confirmedOrder - total.cancelledOrder"
                title="訂單總數量"
                :content="
                  '成立：' +
                  formatNumber(total.confirmedOrder, 0) +
                  '／取消：' +
                  formatNumber(total.cancelledOrder, 0)
                "
              ></CardStatNumber>
            </v-col>
            <v-col cols="12">
              <CardStatNumber
                :number="total.confirmed - total.cancelled"
                title="交易點數總額"
                :content="
                  '成立：' +
                  formatNumber(total.confirmed, 0) +
                  '／取消：' +
                  formatNumber(total.cancelled)
                "
              ></CardStatNumber>
            </v-col>
            <!-- <v-col cols="12">
              <CardStatNumber
                :number="total.confirmed"
                title="交易成立點數總額"
              ></CardStatNumber>
            </v-col>
            <v-col cols="12">
              <CardStatNumber
                :number="total.cancelled"
                title="交易取消點數總額"
              ></CardStatNumber>
            </v-col> -->
            <!-- <v-col cols="12">
              <CardStatNumber :number="0" title="交易總額"></CardStatNumber>
            </v-col> -->
          </v-row>
        </v-col>
        <v-col md="5" cols="12">
          <LineChart
            chart-id="line-chart-trend"
            :datasets="chartData.order"
            :labels="chartLabels"
          ></LineChart>
        </v-col>
        <v-col md="5" cols="12">
          <LineChart
            chart-id="line-chart-point"
            contentType="points"
            :datasets="chartData.points"
            :labels="chartLabels"
          ></LineChart>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableDetailHeaders"
        :items="details"
        :options.sync="pagination"
        :server-items-length="totalDetails"
        :loading="isDetailLoading"
        :loading-text="$t(`common['Loading... Please wait']`)"
        :no-data-text="$t(`common['No Data']`)"
        :no-results-text="$t(`common['No Data']`)"
        :footer-props="{
          'items-per-page-text': $t(`common['Rows per page']`),
        }"
        :page.sync="pagination.page"
        hide-default-footer
        @page-count="pagination.pageCount = $event"
        :items-per-page="pagination.itemsPerPage"
      >
        <template v-slot:item.pointAmount="{ item }">
          {{ formatNumber(item.pointAmount, 0) }}
        </template>
        <template v-slot:item.orderStatus="{ item }">
          <span v-if="item.orderStatus === 'confirmed'"> 已成立</span>
          <span v-else> 已取消</span>
        </template>
        <template v-slot:item.settlementStatus="{ item }">
          <span v-if="item.settlementStatus === 'open'"> 未請款</span>
          <span v-else-if="item.settlementStatus === 'closed'"> 已結算</span>
          <span v-else-if="item.settlementStatus === 'withdrawing'">
            請款中
          </span>
          <span v-else> 已付款 </span>
        </template>
        <template v-slot:item.createdTs="{ item }">
          <span>{{
            item.createdTs
              ? moment.unix(item.createdTs).format("YYYY/MM/DD HH:mm:ss")
              : ""
          }}</span>
        </template>
        <template v-slot:item.updatedAt="props">
          <span>{{
            props.item.orderStatus === "confirmed"
              ? moment.unix(props.item.createdTs).format("YYYY/MM/DD HH:mm:ss")
              : moment
                  .unix(props.item.cancelledTs)
                  .format("YYYY/MM/DD HH:mm:ss")
          }}</span>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="card-padding">
      <Pagination
        :pagination="pagination"
        :total="totalDetails"
        :loading="isDetailLoading"
        @update="getDataFromApi"
      ></Pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { extendMoment } from "moment-range";
import { mapState } from "vuex";
import { formatNumber } from "src/util/utils";
import { fetchPointSettings } from "src/apis/fetchData";

import { fetchStoreOrders, fetchStoreOrderStats } from "src/apis/pointsCore";
import LineChart from "./LineChart.vue";
// import BarChart from "./BarChart.vue";
// import MixedChart from "./MixedChart.vue";

import CardStatNumber from "./CardStatNumber.vue";
import Pagination from "../../Campaigns/Widgets/Pagination.vue";

export default {
  name: "TrendChart",
  components: { LineChart, CardStatNumber, Pagination },
  props: {
    entityType: {
      type: String,
      default: null,
      required: true,
    },
    storeId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      moment: extendMoment(moment),
      formatNumber: formatNumber,
      isLoaded: false,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      showStartPicker: false,
      showEndPicker: false,
      startDate: null,
      endDate: null,
      search: null,
      isDetailLoading: false,
      details: [],
      totalDetails: 0,
      tableDetailHeaders: [
        {
          text: "交易編號",
          sortable: false,
          filterable: false,
          value: "merchantOrderId",
        },
        {
          text: "消費者",
          sortable: false,
          filterable: false,
          value: "giverEntityName",
        },
        {
          text: "聯絡電話",
          sortable: false,
          filterable: false,
          value: "giverEntityPhone",
        },
        {
          text: "交易點數",
          sortable: false,
          filterable: false,
          value: "pointAmount",
        },

        {
          text: "交易狀態",
          sortable: false,
          filterable: false,
          value: "orderStatus",
        },
        {
          text: "請款狀態",
          sortable: false,
          filterable: false,
          value: "settlementStatus",
        },
        {
          text: "訂單時間",
          sortable: false,
          filterable: false,
          value: "createdTs",
        },
        {
          text: "更新時間",
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },
      ],
      periods: [],
      chartLabels: [],
      chartData: {
        order: [],
        points: [],
      },
      chartOptions: null,
      availablePoints: [],
      selectedPointId: null,
      // total: {
      //   confirmed: 0,
      //   cancelled: 0,
      //   confirmedOrder: 0,
      //   cancelledOrder: 0,
      // },
      orderStats: [],
      // isChartDataLoading: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    total() {
      let total = {
        confirmed: 0,
        cancelled: 0,
        confirmedOrder: 0,
        cancelledOrder: 0,
      };
      if (this.orderStats.length) {
        this.orderStats.forEach((obj) => {
          total.cancelled += obj.cancelledAmount;
          total.cancelledOrder += obj.cancelledOrders;
          total.confirmed += obj.confirmedAmount;
          total.confirmedOrder += obj.confirmedOrders;
        });
      }
      // if (this.details.length) {
      //   this.details.forEach((obj) => {
      //     if (obj.orderStatus === "cancelled") {
      //       total.cancelled += obj.pointAmount;
      //       total.cancelledOrder += 1;
      //     } else {
      //       total.confirmed += obj.pointAmount;
      //       total.confirmedOrder += 1;
      //     }
      //     console.log(
      //       `orderStatus:${obj.orderStatus}, ${obj.pointAmount}, ${total.confirmed}, ${total.cancelled}`
      //     );
      //   });
      // }
      return total;
    },
  },
  watch: {
    // storeId: {
    //   handler() {
    //     if (this.isLoaded && this.selectedPointId) {
    //       this.getDataFromApi(true);
    //     }
    //   },
    // },
    isLoaded: {
      handler(newVal) {
        if (newVal === true && this.storeId) {
          this.getDataFromApi(true);
        }
      },
    },
  },
  created() {},
  beforeMount() {
    this.getPointSettings();
  },
  mounted() {
    this.startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
    // this.getPointSettings();
    // this.getDataFromApi();
  },
  methods: {
    close() {
      this.details = [];
      this.totalDetails = 0;
      // this.total = {
      //   confirmed: 0,
      //   cancelled: 0,
      //   confirmedOrder: 0,
      //   cancelledOrder: 0,
      // };
      this.$emit("close");
    },
    getPointSettings() {
      this.isLoaded = false;
      // console.log("before ", this.pointSettings);
      if (this.pointSettings && this.pointSettings.length) {
        this.availablePoints = [...this.pointSettings];
        this.selectedPointId = this.availablePoints.length
          ? this.availablePoints[0].id
          : null;
        this.isLoaded = true;
        return;
      }
      fetchPointSettings(this.merchantId)
        .then((res) => {
          console.log("fetchPointSettings done", res);
          this.availablePoints = [...res.data];
          this.selectedPointId = this.availablePoints.length
            ? this.availablePoints[0].id
            : null;
        })
        .catch((e) => {
          console.log("fetchPointSettings failed", e);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    getDataFromApi(queryStats) {
      if (!this.storeId) return;
      if (!this.selectedPointId) return;
      const start = moment(this.startDate);
      const end = moment(this.endDate);
      if (end.diff(start, "days") < 0 || end.diff(start, "months") > 3) {
        this.$swal({
          // target: this.$refs.container,
          text: "搜尋區間最少為一天，最多為三個月，且開始時間不得小於結束時間",
          type: "error",
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          // toast: true,
          // position: "top-center",
          // timer: 3000,
          // timerProgressBar: true,
        });
        return;
      }

      const range = moment.range(start, end);
      this.periods = Array.from(range.by("day"));
      this.periods.map((m) => m.format("YYYY-MM-DD"));

      this.isDetailLoading = true;
      if (!this.openDetailDialog) {
        this.openDetailDialog = true;
      }
      if (queryStats) {
        this.getOrderStats();
      }
      this.getTransactionDetail()
        .then((data) => {
          this.details = [...data.items];
          this.totalDetails = data.total;
        })
        .finally(() => {
          this.isDetailLoading = false;
        });
    },
    getTransactionDetail() {
      this.details = [];
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        // console.log("pagination", this.pagination);
        const start = moment(this.startDate);
        let end = moment(this.endDate);
        // if (end.diff(start, "days") === 0) {
        //   end = moment(end.format("YYYY-MM-DD") + " 23:59:59");
        // }
        end = moment(end.format("YYYY-MM-DD") + " 23:59:59");
        let query = {
          // from_ts: 0,
          // to_ts: moment().unix(),
          from_ts: moment(start).unix(),
          to_ts: moment(end).unix(),
          offset: (page - 1) * itemsPerPage || 0,
          limit: itemsPerPage,
          search: this.search,
        };
        let items = [];
        let total = 0;
        console.log("query:" + JSON.stringify(query));
        fetchStoreOrders(this.merchantId, this.storeId, query)
          .then((res) => {
            console.log("fetchStoreOrders done:" + JSON.stringify(res));
            items = [...res.data.orders];
            total = res.data.total;
            // this.total.confirmed = res.data.totalConfirmedAmount;
            // this.total.cancelled = res.data.totalCancelledAmount;
            // this.total.confirmedOrder = res.data.totalConfirmedOrders;
            // this.total.cancelledOrder = res.data.totalCancelledOrders;
          })
          .catch((e) => {
            console.log("fetchStoreOrders failed", e);
            this.$swal({
              text: "無法取得特店交易明細",
              type: "error",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              resolve({
                items,
                total,
              });
            }, 1000);
          });
      });
    },
    getOrderStats() {
      const start = moment(this.startDate);
      let end = moment(this.endDate);
      end = moment(end.format("YYYY-MM-DD") + " 23:59:59");
      let query = {
        from_ts: moment(start).unix(),
        to_ts: moment(end).unix(),
        search: this.search,
      };
      console.log("query:" + JSON.stringify(query));
      fetchStoreOrderStats(this.merchantId, this.storeId, query)
        .then((res) => {
          console.log("fetchStoreOrderStats done:" + JSON.stringify(res.data));
          // console.log("fetchStoreOrderStats done");
          this.orderStats = res.data;
          this.calcChartData();
        })
        .catch((e) => {
          console.log("fetchStoreOrderStats failed", e);
          this.$swal({
            text: "無法取得特店交易統計資訊",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    calcChartData() {
      this.chartLabels.length = 0;
      this.chartData.order.length = 0;
      this.chartData.points.length = 0;

      let confirmed = [];
      let cancelled = [];
      let confirmedPointAmount = [];
      let cancelledPointAmount = [];
      this.periods.forEach((p) => {
        let dayString = p.format("YYYY-MM-DD").toString();
        this.chartLabels.push(dayString);
        // find stats
        const found = this.orderStats.find((i) => i.orderDate === dayString);
        console.log("---found", found);
        if (found) {
          confirmed.push(found.confirmedOrders);
          cancelled.push(found.cancelledOrders);
          confirmedPointAmount.push(found.confirmedAmount);
          cancelledPointAmount.push(found.cancelledAmount);
        } else {
          confirmed.push(0);
          cancelled.push(0);
          confirmedPointAmount.push(0);
          cancelledPointAmount.push(0);
        }
      });
      // console.log("chartLables", this.chartLabels);
      console.log("--------", confirmed, cancelled);
      // 把line chart當mixed chart用
      this.chartData.order.push({
        type: "line",
        label: "成立訂單量",
        tension: 0.4,
        pointRadius: 2,
        pointBackgroundColor: "#e91e63",
        borderColor: "#e91e63",
        borderWidth: 3,
        backgroundColor: "transparent",
        data: confirmed,
        maxBarThickness: 6,
      });
      this.chartData.order.push(
        {
          type: "line",
          label: "取消訂單量",
          tension: 0.4,
          pointRadius: 2,
          pointBackgroundColor: "#3A416F",
          borderColor: "#3A416F",
          borderWidth: 3,
          backgroundColor: "transparent",
          data: cancelled,
          maxBarThickness: 6,
        }
        // {
        //   type: "bar",
        //   label: "累積有效訂單數",
        //   weight: 5,
        //   tension: 0.4,
        //   borderWidth: 0,
        //   pointBackgroundColor: "#9CD1DE",
        //   borderColor: "#9CD1DE",
        //   backgroundColor: "#9CD1DE",
        //   borderRadius: 4,
        //   borderSkipped: false,
        //   data: orders,
        //   maxBarThickness: 10,
        // }
      );
      this.chartData.points.push({
        label: "成立交易點數",
        tension: 0.4,
        pointRadius: 2,
        pointBackgroundColor: "#e91e63",
        borderColor: "#e91e63",
        borderWidth: 3,
        backgroundColor: "transparent",
        data: confirmedPointAmount,
        maxBarThickness: 6,
      });
      this.chartData.points.push(
        {
          label: "取消交易點數",
          tension: 0.4,
          pointRadius: 2,
          pointBackgroundColor: "#3A416F",
          borderColor: "#3A416F",
          borderWidth: 3,
          backgroundColor: "transparent",
          data: cancelledPointAmount,
          maxBarThickness: 6,
        }
        // {
        //   type: "bar",
        //   label: "累積有效交易點數",
        //   weight: 5,
        //   tension: 0.4,
        //   borderWidth: 0,
        //   pointBackgroundColor: "#9CD1DE",
        //   borderColor: "#9CD1DE",
        //   backgroundColor: "#9CD1DE",
        //   borderRadius: 4,
        //   borderSkipped: false,
        //   data: points,
        //   maxBarThickness: 10,
        // }
      );
    },
    // calcChartData() {
    //   this.chartLabels.length = 0;
    //   this.chartData.order.length = 0;
    //   this.chartData.points.length = 0;
    //   this.periods.forEach((p) => {
    //     this.chartLabels.push(p.format("YYYY-MM-DD").toString());
    //   });
    //   // console.log("chartLables", this.chartLabels);
    //   let grouped = this.details.reduce((acc, obj) => {
    //     // 訂單時間
    //     const key =
    //       obj.orderStatus === "cancelled"
    //         ? moment.unix(obj.cancelledTs).format("YYYY-MM-DD")
    //         : moment.unix(obj.createdTs).format("YYYY-MM-DD");
    //     // 訂單數量
    //     if (!acc[key]) {
    //       acc[key] = {
    //         confirmed: obj.orderStatus === "confirmed" ? 1 : 0,
    //         cancelled: obj.orderStatus === "cancelled" ? 1 : 0,
    //         confirmedPointAcmount:
    //           obj.orderStatus === "confirmed" ? obj.pointAmount : 0,
    //         cancelledPointAcmount:
    //           obj.orderStatus === "cancelled" ? obj.pointAmount : 0,
    //       };
    //     } else {
    //       acc[key]["confirmed"] += obj.orderStatus === "confirmed" ? 1 : 0;
    //       acc[key]["cancelled"] += obj.orderStatus === "cancelled" ? 1 : 0;
    //       acc[key]["confirmedPointAcmount"] +=
    //         obj.orderStatus === "confirmed" ? obj.pointAmount : 0;
    //       acc[key]["cancelledPointAcmount"] +=
    //         obj.orderStatus === "cancelled" ? obj.pointAmount : 0;
    //     }
    //     return acc;
    //   }, {});

    //   console.log("grouped:", grouped);
    //   let accOrder = 0;
    //   let accPoints = 0;
    //   let confirmed = [];
    //   let cancelled = [];
    //   let orders = [];
    //   let points = [];
    //   let confirmedPointAcmount = [];
    //   let cancelledPointAcmount = [];
    //   this.chartLabels.forEach((d) => {
    //     if (grouped[d]) {
    //       confirmed.push(grouped[d].confirmed);
    //       cancelled.push(grouped[d].cancelled);
    //       accOrder += grouped[d].confirmed - grouped[d].cancelled;
    //       orders.push(accOrder);
    //       confirmedPointAcmount.push(grouped[d].confirmedPointAcmount);
    //       cancelledPointAcmount.push(grouped[d].cancelledPointAcmount);
    //       accPoints +=
    //         grouped[d].confirmedPointAcmount - grouped[d].cancelledPointAcmount;
    //       points.push(accPoints);
    //     } else {
    //       confirmed.push(0);
    //       cancelled.push(0);
    //       orders.push(0);
    //       confirmedPointAcmount.push(0);
    //       cancelledPointAcmount.push(0);
    //       points.push(0);
    //     }
    //   });
    //   // 把line chart當mixed chart用
    //   this.chartData.order.push({
    //     type: "line",
    //     label: "成立訂單量",
    //     tension: 0.4,
    //     pointRadius: 2,
    //     pointBackgroundColor: "#e91e63",
    //     borderColor: "#e91e63",
    //     borderWidth: 3,
    //     backgroundColor: "transparent",
    //     data: confirmed,
    //     maxBarThickness: 6,
    //   });
    //   this.chartData.order.push(
    //     {
    //       type: "line",
    //       label: "取消訂單量",
    //       tension: 0.4,
    //       pointRadius: 2,
    //       pointBackgroundColor: "#3A416F",
    //       borderColor: "#3A416F",
    //       borderWidth: 3,
    //       backgroundColor: "transparent",
    //       data: cancelled,
    //       maxBarThickness: 6,
    //     }
    //     // {
    //     //   type: "bar",
    //     //   label: "累積有效訂單數",
    //     //   weight: 5,
    //     //   tension: 0.4,
    //     //   borderWidth: 0,
    //     //   pointBackgroundColor: "#9CD1DE",
    //     //   borderColor: "#9CD1DE",
    //     //   backgroundColor: "#9CD1DE",
    //     //   borderRadius: 4,
    //     //   borderSkipped: false,
    //     //   data: orders,
    //     //   maxBarThickness: 10,
    //     // }
    //   );
    //   this.chartData.points.push({
    //     label: "成立交易點數",
    //     tension: 0.4,
    //     pointRadius: 2,
    //     pointBackgroundColor: "#e91e63",
    //     borderColor: "#e91e63",
    //     borderWidth: 3,
    //     backgroundColor: "transparent",
    //     data: confirmedPointAcmount,
    //     maxBarThickness: 6,
    //   });
    //   this.chartData.points.push(
    //     {
    //       label: "取消交易點數",
    //       tension: 0.4,
    //       pointRadius: 2,
    //       pointBackgroundColor: "#3A416F",
    //       borderColor: "#3A416F",
    //       borderWidth: 3,
    //       backgroundColor: "transparent",
    //       data: cancelledPointAcmount,
    //       maxBarThickness: 6,
    //     }
    //     // {
    //     //   type: "bar",
    //     //   label: "累積有效交易點數",
    //     //   weight: 5,
    //     //   tension: 0.4,
    //     //   borderWidth: 0,
    //     //   pointBackgroundColor: "#9CD1DE",
    //     //   borderColor: "#9CD1DE",
    //     //   backgroundColor: "#9CD1DE",
    //     //   borderRadius: 4,
    //     //   borderSkipped: false,
    //     //   data: points,
    //     //   maxBarThickness: 10,
    //     // }
    //   );

    //   // this.isChartDataLoading = false;
    // },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
</style>
