<template>
  <v-card>
    <v-toolbar dark color="blue darken-2">
      <v-toolbar-title>收支明細</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn fab plain @click="close">
          <v-icon> fas fa-times</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-title class="justify-center">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            v-model="selectedPointId"
            :label="$t(`points['Name']`)"
            :items="availablePoints"
            item-text="name"
            item-value="id"
            required
            :disabled="isDetailLoading"
            :no-data-text="$t(`common['No Data']`)"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="搜尋電話"
            single-line
            hide-details
            :disabled="isDetailLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-menu
            v-model="showStartPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            name="startDate"
            :disabled="isDetailLoading"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="startDate"
                label="開始日期"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              color="blue-grey lighten-1"
              @input="showStartPicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2">
          <v-menu
            v-model="showEndPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            name="endDate"
            :disabled="isDetailLoading"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="endDate"
                label="結束日期"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              color="blue-grey lighten-1"
              @input="showEndPicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2" :class="$vuetify.breakpoint.xs ? '' : 'pt-4'">
          <v-btn
            color="blue-grey"
            class="
              font-weight-normal
              text-capitalize
              ma-2
              white--text
              py-3
              px-6
              ms-3
            "
            :disabled="isDetailLoading"
            :loading="isDetailLoading"
            @click="
              pagination.page = 1;
              getDataFromApi();
            "
          >
            {{ $t(`common["Search"]`) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="tableDetailHeaders"
        :items="details"
        :options.sync="pagination"
        :server-items-length="totalDetails"
        :loading="isDetailLoading"
        :loading-text="$t(`common['Loading... Please wait']`)"
        :no-data-text="$t(`common['No Data']`)"
        :no-results-text="$t(`common['No Data']`)"
        :footer-props="{
          'items-per-page-text': $t(`common['Rows per page']`),
        }"
        :page.sync="pagination.page"
        hide-default-footer
        @page-count="pagination.pageCount = $event"
        :items-per-page="pagination.itemsPerPage"
      >
        <template v-slot:item.pointAmount="{ item }">
          {{ formatNumber(item.pointAmount, 0) }}
        </template>
        <template v-slot:item.orderStatus="{ item }">
          <span v-if="item.orderStatus === 'confirmed'"> 已成立</span>
          <span v-else> 已取消</span>
        </template>
        <template v-slot:item.settlementStatus="{ item }">
          <span v-if="item.settlementStatus === 'open'"> 未請款</span>
          <span v-else-if="item.settlementStatus === 'closed'"> 已結算</span>
          <span v-else-if="item.settlementStatus === 'withdrawing'">
            請款中
          </span>
          <span v-else> 已付款 </span>
        </template>
        <template v-slot:item.createdTs="{ item }">
          <span>{{
            item.createdTs
              ? moment.unix(item.createdTs).format("YYYY/MM/DD HH:mm:ss")
              : ""
          }}</span>
        </template>
        <template v-slot:item.updatedAt="props">
          <span>{{
            props.item.orderStatus === "confirmed"
              ? moment.unix(props.item.createdTs).format("YYYY/MM/DD HH:mm:ss")
              : moment
                  .unix(props.item.cancelledTs)
                  .format("YYYY/MM/DD HH:mm:ss")
          }}</span>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="card-padding">
      <Pagination
        :pagination="pagination"
        :total="totalDetails"
        :loading="isDetailLoading"
        @update="getDataFromApi"
      ></Pagination>
    </v-card-actions>
    <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" dark @click="openDetailDialog = false">
              Close
            </v-btn>
          </v-card-actions> -->
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { formatNumber } from "src/util/utils";

import { fetchStoreOrders } from "src/apis/pointsCore";
import Pagination from "../../Campaigns/Widgets/Pagination.vue";
import { fetchPointSettings } from "src/apis/fetchData";

export default {
  name: "Transactions",
  components: { Pagination },
  props: {
    entityType: {
      type: String,
      default: null,
      required: true,
    },
    storeId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      isLoaded: false,
      selectedPointId: null,
      availablePoints: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      showStartPicker: false,
      showEndPicker: false,
      startDate: null,
      endDate: null,
      search: null,
      isDetailLoading: false,
      totalDetails: 0,
      details: [],
      tableDetailHeaders: [
        {
          text: "交易編號",
          sortable: false,
          filterable: false,
          value: "merchantOrderId",
        },
        {
          text: "消費者",
          sortable: false,
          filterable: false,
          value: "giverEntityName",
        },
        {
          text: "聯絡電話",
          sortable: false,
          filterable: false,
          value: "giverEntityPhone",
        },
        {
          text: "交易點數",
          sortable: false,
          filterable: false,
          align: "right",
          value: "pointAmount",
        },

        {
          text: "交易狀態",
          sortable: false,
          filterable: false,
          value: "orderStatus",
        },
        {
          text: "請款狀態",
          sortable: false,
          filterable: false,
          value: "settlementStatus",
        },
        {
          text: "訂單時間",
          sortable: false,
          filterable: false,
          value: "createdTs",
        },
        {
          text: "更新時間",
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
  },
  watch: {
    // pagination: {
    //   handler() {
    //     this.getDataFromApi();
    //   },
    //   deep: true,
    // },
    storeId: {
      handler() {
        if (this.isLoaded && this.selectedPointId) {
          this.getDataFromApi();
        }
      },
    },
    isLoaded: {
      handler(newVal) {
        if (newVal === true && this.storeId) {
          this.getDataFromApi();
        }
      },
    },
    // search: {
    //   handler() {
    //     this.getDataFromApi();
    //   },
    // },
  },
  created() {},
  beforeMount() {
    this.getPointSettings();
  },
  mounted() {
    this.startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
  },
  methods: {
    close() {
      this.details = [];
      this.totalDetails = 0;
      this.$emit("close");
    },
    getPointSettings() {
      this.isLoaded = false;
      // console.log("before ", this.pointSettings);
      if (this.pointSettings && this.pointSettings.length) {
        this.availablePoints = [...this.pointSettings];
        this.selectedPointId = this.availablePoints.length
          ? this.availablePoints[0].id
          : null;
        this.isLoaded = true;
        return;
      }
      fetchPointSettings(this.merchantId)
        .then((res) => {
          console.log("fetchPointSettings done", res);
          this.availablePoints = [...res.data];
          this.selectedPointId = this.availablePoints.length
            ? this.availablePoints[0].id
            : null;
        })
        .catch((e) => {
          console.log("fetchPointSettings failed", e);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    getDataFromApi() {
      if (!this.storeId) return;
      if (!this.selectedPointId) return;
      const start = moment(this.startDate);
      const end = moment(this.endDate);
      if (end.diff(start, "days") < 0 || end.diff(start, "months") > 3) {
        this.$swal({
          // target: this.$refs.container,
          text: "搜尋區間最少為一天，最多為三個月，且開始時間不得小於結束時間",
          type: "error",
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          // toast: true,
          // position: "top-center",
          // timer: 3000,
          // timerProgressBar: true,
        });
        return;
      }

      this.isDetailLoading = true;
      if (!this.openDetailDialog) {
        this.openDetailDialog = true;
      }
      this.getTransactionDetail()
        .then((data) => {
          this.details = [...data.items];
          this.totalDetails = data.total;
        })
        .finally(() => {
          this.isDetailLoading = false;
        });
    },
    getTransactionDetail() {
      this.details = [];
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        // console.log("pagination", this.pagination);
        const start = moment(this.startDate);
        let end = moment(this.endDate);
        // if (end.diff(start, "days") === 0) {
        //   end = moment(end.format("YYYY-MM-DD") + " 23:59:59");
        // }
        end = moment(end.format("YYYY-MM-DD") + " 23:59:59");
        let query = {
          // from_ts: 0,
          // to_ts: moment().unix(),
          from_ts: moment(start).unix(),
          to_ts: moment(end).unix(),
          offset: (page - 1) * itemsPerPage || 0,
          limit: itemsPerPage,
          search: this.search,
        };
        let items = [];
        let total = 0;
        console.log("query:" + JSON.stringify(query));
        fetchStoreOrders(this.merchantId, this.storeId, query)
          .then((res) => {
            console.log("fetchStoreOrders done:" + JSON.stringify(res));
            items = [...res.data.orders];
            total = res.data.total;
          })
          .catch((e) => {
            console.log("fetchStoreOrders failed", e);
            this.$swal({
              text: "無法取得特店交易明細",
              type: "error",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              resolve({
                items,
                total,
              });
            }, 1000);
          });
      });
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
</style>
